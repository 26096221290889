import React, { useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineWarning } from "react-icons/ai";
import {
  AlertContainer,
  DeliveryContainer,
  DeliveryDescription,
  DeliveryTitle,
  DeliveryTitleContainer,
  GpsActiveContainer,
  GpsInactiveText,
  InactiveGpsContainer,
  VolumesInputsContainer,
} from "./styles";
import { formatDate } from "~/utils/treat_date";
import { useDispatch, useSelector } from "react-redux";
import PathIcon from "~/assets/images/icons/Path.svg";
import { sendToSentry } from "~/sentry";
import {
  DeliveryInfosContainer,
  InfoGroup,
  InfoTitle,
  InfoValue,
} from "../Main/components/CollectCard/styles";
import { DateLimitToCollect } from "../Main/components/CollectCard";
import history from "~/services/history";
import CustomButton, { CustomDarkButton } from "~/components/CustomButton";
import { format } from "date-fns";
import PictureButton from "~/components/PictureButton";
import {
  CloseButton,
  ImageContainer,
  PreviewImg,
} from "~/components/PreviewImg";
import { getBase64FromBlob } from "~/utils/blob_to_base64";
import { deliveredPackageAction } from "~/store/modules/queue/actions";
import { updateDestinationsInfo } from "~/store/modules/destinations/actions";
import RequireImageModal from "../Destinations/components/RequireImageModal";
import { toast } from "react-toastify";
import {
  clearCurrentLocation,
  getCurrentLocationRequest,
} from "~/store/modules/geolocation/actions";
import ConfirmationModal from "../Destinations/components/ConfirmationModal";
import GpsUnavailableModal from "../Destinations/components/GpsUnavailableModal";
import { TextError } from "../Login/styles";

export default function Delivery() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { item } = location.state || {};

  const connected = useSelector(state => state.connection.Connected);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [volumesNumbers, setVolumesNumbers] = useState(0);
  const [picture, setPicture] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");
  const [cameraError, setCameraError] = useState(false);
  const [showRequireImageModal, setShowRequireImageModal] = useState(false);
  const [missingVolumeDataError, setMissingVolumeDataError] = useState(false);

  const itemType = item.idTipoEntrega;

  const cardColor = `${itemType === "E" ? "#2194CD" : "#CA851E"}`;
  const geolocationEnabled = useSelector(
    states => states.geolocation.geolocationEnabled
  );
  const { coords, lastCoords } = useSelector(states => states.geolocation);

  const handleGoBack = () => {
    history.goBack(); // This will navigate back to the previous route
  };

  function handleValidateLocationAndOpenConfirm() {
    if (!cameraError && !picture) {
      setShowRequireImageModal(true);
      toast.error(
        "Não foi inserida uma imagem, confere a sua pasta de downloads"
      );
    }
    if (!picture) {
      setShowRequireImageModal(true);
      toast.error("Não foi inserida uma imagem");
    } else {
      dispatch(clearCurrentLocation());
      dispatch(getCurrentLocationRequest());
      setShowConfirmModal(true);
    }
  }

  function handleSavePicture(blob, filename) {
    setPicture({ blob, filename });
    setPictureUrl(URL.createObjectURL(blob));
  }

  function handleCameraError() {
    setCameraError(true);
    const event = {
      message: "opening camera error",
    };
    sendToSentry(event, connected);
  }

  const dtEntrega = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxx");

  async function handleConfirmDeliverySent() {
    if (itemType === "R" && !volumesNumbers) {
      toast.error("Campo volume é obrigatório");
      setMissingVolumeDataError(true);
      return;
    }
    let latitude;
    let longitude;
    if (!coords) {
      if (!lastCoords) {
        latitude = "";
        longitude = "";
      } else {
        latitude = lastCoords.latitude;
        longitude = lastCoords.longitude;
      }
    } else {
      latitude = coords.latitude;
      longitude = coords.longitude;
    }
    const imageBase64 = await getBase64FromBlob(picture.blob);
    const itemToSend = {
      ...item,
      geoCoordenadaEntrega: { latitude, longitude },
      dataEntrega: dtEntrega,
      entregue: "S",
      picture: imageBase64,
      ...(itemType === "R" ? { recolherVolumes: volumesNumbers } : {}),
    };
    deliveredPackageAction(itemToSend, dispatch, connected);
    dispatch(updateDestinationsInfo());
    handleGoBack();
  }

  return (
    <DeliveryContainer>
      <>
        {geolocationEnabled ? (
          <ConfirmationModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            handleConfirmDeliverySent={handleConfirmDeliverySent}
          />
        ) : (
          <GpsUnavailableModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            handleConfirmDeliverySent={handleConfirmDeliverySent}
          />
        )}
        <RequireImageModal
          show={showRequireImageModal}
          setShow={setShowRequireImageModal}
        />
      </>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "16px",
        }}
      >
        <IoIosArrowBack color={cardColor} size={20} onClick={handleGoBack} />
        <DeliveryTitleContainer>
          <DeliveryTitle cardColor={cardColor}>
            {item.cdCliente}
            {item.nrDvCliente} - {item.nomeComercialFantasia.nomeFantasia}
          </DeliveryTitle>
          <DeliveryDescription>
            Emitido em {formatDate(item.dtGeracao)}
          </DeliveryDescription>
        </DeliveryTitleContainer>
      </div>
      <hr style={{ width: "100%" }} />
      <div
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        {geolocationEnabled ? (
          <GpsActiveContainer>
            <img style={{}} src={PathIcon} />
            <span
              style={{
                fontSize: "14px",
                color: "#60A84E",
              }}
            >
              GPS Ativo
            </span>
          </GpsActiveContainer>
        ) : (
          <InactiveGpsContainer>
            <AlertContainer>
              <AiOutlineWarning
                style={{
                  color: "#143C96",
                }}
                size={25}
              />
            </AlertContainer>
            <GpsInactiveText>
              Por favor, verifique se o seu GPS está ativo ou vá para outro
              local para melhorar o sinal
            </GpsInactiveText>
          </InactiveGpsContainer>
        )}
      </div>
      <div
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          marginTop: "16px",
        }}
      >
        {!(itemType === "E") ? (
          <DateLimitToCollect date={formatDate(item.dtPrevisaoEntrega)} />
        ) : null}
      </div>
      <div
        style={{ paddingLeft: "16px", paddingRight: "16px", marginTop: "12px" }}
      >
        <DeliveryInfosContainer>
          <InfoGroup>
            <InfoTitle>CNPJ</InfoTitle>
            <InfoValue>{item.cnpj}</InfoValue>
          </InfoGroup>
          {itemType === "E" ? (
            <InfoGroup>
              <InfoTitle>Volume</InfoTitle>
              <InfoValue>
                {item.volumes < 10
                  ? item.volumes.toString().padStart(2, "0")
                  : item.volumes}
              </InfoValue>
            </InfoGroup>
          ) : null}
          {itemType === "R" ? (
            <>
              <InfoGroup>
                <InfoTitle>Protocolo/Ordem de Coleta</InfoTitle>
                <InfoValue>{item.nfes?.[0].protocolo}</InfoValue>
              </InfoGroup>
              <InfoGroup>
                <InfoTitle>Peso</InfoTitle>
                <InfoValue>
                  {/* todo:  get dynamic */}
                  {item.nfes?.[0].peso}
                </InfoValue>
              </InfoGroup>
              <div>
                <VolumesInputsContainer>
                  <div
                    onClick={() => {
                      if (volumesNumbers > 0) {
                        setMissingVolumeDataError(false);
                        setVolumesNumbers(prev => prev - 1);
                      }
                    }}
                  >
                    <span style={{ fontSize: "24px" }}>-</span>
                  </div>
                  <span>
                    {volumesNumbers} volume{volumesNumbers > 1 ? "s" : ""}
                  </span>
                  <div
                    onClick={() => {
                      setMissingVolumeDataError(false);
                      setVolumesNumbers(prev => prev + 1);
                    }}
                  >
                    <span style={{ fontSize: "24px" }}>+</span>
                  </div>
                </VolumesInputsContainer>
                {missingVolumeDataError ? (
                  <TextError>Campo obrigatório</TextError>
                ) : null}
              </div>
            </>
          ) : null}
        </DeliveryInfosContainer>
      </div>
      <hr style={{ width: "100%" }} />
      <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
        <DeliveryInfosContainer>
          <InfoGroup>
            <InfoTitle>Número</InfoTitle>
            <InfoValue>{item.enderecoEntrega.numero}</InfoValue>
          </InfoGroup>
          <InfoGroup>
            <InfoTitle>Rua</InfoTitle>
            <InfoValue>{item.enderecoEntrega.rua}</InfoValue>
          </InfoGroup>
          <InfoGroup>
            <InfoTitle>Bairro</InfoTitle>
            <InfoValue>{item.enderecoEntrega.bairro}</InfoValue>
          </InfoGroup>
          <InfoGroup>
            <InfoTitle>Cidade</InfoTitle>
            <InfoValue>{item.enderecoEntrega.cidade}</InfoValue>
          </InfoGroup>
          <InfoGroup>
            <InfoTitle>CEP</InfoTitle>
            <InfoValue>{item.enderecoEntrega.cep}</InfoValue>
          </InfoGroup>
        </DeliveryInfosContainer>
      </div>
      <hr style={{ width: "100%" }} />
      <div
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "100px",
        }}
      >
        <DeliveryInfosContainer
          style={{ display: "flex", flexDirection: "columns" }}
        >
          <InfoGroup>
            <InfoTitle>Número da nota fiscal</InfoTitle>
            {item.nfes.map(nfe => (
              <InfoValue key={nfe.numero}>
                <span
                  style={{
                    fontWeight: "700",
                  }}
                >
                  {nfe.numero}
                </span>
                {itemType === "E" ? (
                  <span style={{ opacity: "0.5" }}>{` (Volume${
                    nfe.volumes > 1 ? "s" : ""
                  }: ${nfe.volumes.toString().padStart(2, "0")} • Tipo ${
                    nfe.tipoNota === "V" ? "Venda" : "Bonificada"
                  })`}</span>
                ) : null}
              </InfoValue>
            ))}
          </InfoGroup>
        </DeliveryInfosContainer>
        {picture && (
          <ImageContainer>
            <PreviewImg src={pictureUrl} alt="Preview imagem anexada" />
            <CloseButton
              onClick={() => {
                setPicture("");
                setPictureUrl("");
              }}
            >
              X
            </CloseButton>
          </ImageContainer>
        )}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          padding: "16px",
          display: "flex",
          gap: "8px",
          marginTop: "16px",
        }}
      >
        <CustomDarkButton
          onClick={handleGoBack}
          height={"40px"}
          text={"Cancelar"}
          isSelected={true}
        />
        {!picture ? (
          <PictureButton
            cardColor={cardColor}
            title={"Anexar Imagem"}
            onSave={handleSavePicture}
            onError={handleCameraError}
          />
        ) : (
          <CustomButton
            bgColor={cardColor}
            height={"40px"}
            text={"Confirmar entrega"}
            isSelected={true}
            onClick={() => {
              handleValidateLocationAndOpenConfirm();
            }}
          />
        )}
      </div>
    </DeliveryContainer>
  );
}
